import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionaryclients.scss";
import {withApplicationState} from "react-application-state";
import Clients from "../../clients/components/clients";

class QuestionaryClients extends Component {
    render() {
        return (
            <div className="QuestionaryClients">
                <Clients cardName={'Klanten'}/>
            </div>
        );
    }
}

export default withApplicationState(withRouter(QuestionaryClients));
