import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/results.scss";

class Results extends Component {
    render() {
        return (
            <div className="Results">
            </div>
        )
    }
}

export default withRouter(Results);
