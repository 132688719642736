import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import QuestionaryDashboard from "../../questionarydashboard";
import QuestionaryClients from "../../questionaryclients";
import QuestionaryContactperson from "../../questionarycontactperson";
import Questionaries from "../../questionaries";
import QuestionaryResult from "../../questionaryresult";
import QuestionaryQuestions from "../../questionaryquestions";
import QuestionaryWelcome from "../../questionarywelcome";
import QuestionaryThanks from "../../questionarythanks";
import QuestionaryEmail from "../../questionaryemail";
import Results from "../../results";


class Router extends Component {
    render() {
        return <Fragment>
            <Switch>
                <Route path="/" exact component={QuestionaryDashboard} />
                <Route path="/klanten" exact component={QuestionaryClients} />
                <Route path="/contactpersonen/:clientnumber" exact  render={(props) => <QuestionaryContactperson {...props} sendingQuestionary={false}/>} />
                <Route path="/enquete" exact component={Questionaries} />
                <Route path="/enquete/:clientnumber" exact component={Questionaries} />
                <Route path="/enquete/:id/defaultvragen" exact render={(props) => <QuestionaryQuestions {...props} questionsType={'defaultQuestions'} />}/>
                <Route path="/enquete/:id/defaultwelkomsttekst" exact render={(props) => <QuestionaryWelcome {...props} welcomeType={'defaultWelcome'}/>}/>
                <Route path="/enquete/:id/welkom" exact render={(props) => <QuestionaryWelcome {...props} welcomeType={'clientWelcome'}/>}/>
                <Route path="/enquete/:id/defaultbedankt" exact render={(props) => <QuestionaryThanks {...props} thanksType={'defaultThanks'}/>}/>
                <Route path="/enquete/:id/bedankt" exact render={(props) => <QuestionaryThanks {...props} thanksType={'clientThanks'}/>}/>
                <Route path="/enquete/:id/vragen/:clientnumber" exact render={(props) => <QuestionaryQuestions {...props} questionsType={'clientQuestions'} />}/>
                <Route path="/enquete/:id/contactpersonen/:clientnumber" exact render={(props) => <QuestionaryContactperson {...props} sendingQuestionary={true}/>}/>
                <Route path="/email/:id" exact component={QuestionaryEmail} />
                <Route path="/uitslagen" exact component={Results}/>
                <Route path="/uitslagen/:clientnumber/:questionaryId" exact component={QuestionaryResult}/>
            </Switch>
        </Fragment>;
    }
}

export default withRouter(Router);
