import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/contenteditor.scss";
import {MegadraftEditor} from "megadraft";
import 'megadraft/dist/css/megadraft.css';
import icons from 'megadraft/lib/icons';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

const toolbarActions = [
    { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
    { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
    { type: "block", label: "H2", style: "header-two", icon: icons.H2Icon },
    { type: "separator" },
    {
        type: "block",
        label: "UL",
        style: "unordered-list-item",
        icon: icons.ULIcon
    },
    {
        type: "block",
        label: "OL",
        style: "ordered-list-item",
        icon: icons.OLIcon
    },
    { type: "separator" },
    {
        type: "entity",
        label: "Link",
        style: "link",
        entity: "LINK",
        icon: icons.LinkIcon
    },
    {
        type: "block",
        label: "QT",
        style: "blockquote",
        icon: icons.BlockQuoteIcon
    }
];

class ContentEditor extends Component {
    constructor(props) {
        super(props);
        const editorContent = stateFromHTML(props.initialContent);
        console.log(editorContent)
        this.state = {editorState: EditorState.createWithContent(editorContent)};
    }
    onChange = (editorState) => {

        this.setState({editorState});
        this.props.onChange(stateToHTML(editorState.getCurrentContent()))
    }

    render() {
        return (
            <div className="ContentEditor">
                <MegadraftEditor
                    editorState={this.state.editorState}
                    actions={toolbarActions}
                    onChange={this.onChange}/>
            </div>
        );
    }
}

export default withRouter(ContentEditor);
