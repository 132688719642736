import React, { Component } from 'react';
import {withRouter, Link} from "react-router-dom";
import "../assets/questionaryquestions.scss";
import {Button, Modal, Toast} from 'antd-mobile';
import applicationConfig from "../../../config/application";
import {httpGet, httpPost, httpDelete} from "../../../lib/api";
import PropTypes from "prop-types";
import Card from "../../card";
import {faStar, faTimesCircle, faEdit} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {keysToLower} from "../../../lib/utils";
import {withApplicationState} from "react-application-state";

class QuestionaryQuestions extends Component {
    static propTypes = {
        questionsType: PropTypes.string,
    };

    constructor(props){
        super(props);
        this.state = {
            changeName: '',
            categoryName: '',
            questions: [],
            text: '',
            type: '',
            questionaryId: props.match.params.id,
            defaultCategories: {},
            catVisible: false,
            nameVisble: false,
            questionary: {},
            clientnumber: props.match.params.clientnumber,
            saving: false
        }
    }

    componentWillMount = async () => {

        await this.refreshQuestions();

        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const categories = await httpGet(`/categories`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (categories) {
            this.setState({
                defaultCategories: categories.response,
                defaultCategoryId: categories.response[0].Id
            })
        }
    };

    refreshQuestions = async () => {
        const {questionsType} = this.props;
        const {questionaryId} = this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;

        if (questionsType === 'defaultQuestions'){
            const questions = await httpGet(`/default/questionaries/${questionaryId}/questions`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if (questions) {
                this.setState({
                    questions: questions.response
                })
            }
            const info = await httpGet(`/default/questionaries/${questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if (info) {
                this.setState({
                    changeName: info.response.Name,
                    categoryName: info.response.Name,
                    questionary: info.response
                })
            }
        } else {
            const questions = await httpGet(`/questionaries/${questionaryId}/questions`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if (questions) {
                this.setState({
                    questions: questions.response
                })
            }
            const info = await httpGet(`/questionaries/${questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if (info) {
                this.setState({
                    categoryName: info.response.Name,
                    questionary: info.response
                })
            }
        }
    };

    changeQuestion = (index, question) => {
        const questions = [...this.state.questions];
        questions[index].Text = question;
        this.setState({questions});
    };

    addQuestions = async (type) => {
        const {questionsType} = this.props;
        const {text, questionaryId, questions} = this.state;
        const sequence = questions.map(question => question.Sequence).reduce((max, cur) => {
            if(max < cur)
                return cur;
            return max;
        }, 0) + 1;

        if(questionsType === 'defaultQuestions'){
            const {jwtToken} = this.props.appState.state;
            const {logout} = this.props.appState.actions;

            await httpPost(`/default/questionaries/${questionaryId}/questions`, {
                text: text,
                sequence: sequence,
                type: type,
                questionaryId: questionaryId
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

            const uriBase = questionsType === 'defaultQuestions' ?
                `/default/questionaries/${questionaryId}/questions/` :
                `/questionaries/${questionaryId}/questions/`;

            for(let index in questions){
                const question = questions[index];
                await httpPost(`${uriBase}${question.Id}`, keysToLower(question), jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            }

            await this.refreshQuestions();
        } else {
            const {jwtToken} = this.props.appState.state;
            const {logout} = this.props.appState.actions;

            await httpPost(`/questionaries/${questionaryId}/questions`, {
                text: text,
                sequence: sequence,
                type: type,
                questionaryId: questionaryId
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

            const uriBase = questionsType === 'defaultQuestions' ?
                `/default/questionaries/${questionaryId}/questions/` :
                `/questionaries/${questionaryId}/questions/`;

            for(let index in questions){
                const question = questions[index];
                await httpPost(`${uriBase}${question.Id}`, keysToLower(question), jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            }

            await this.refreshQuestions();
        }
    };

    saveQuestion = async () => {
        const {questionsType} = this.props;
        const {questions, questionaryId} = this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;

        const uriBase = questionsType === 'defaultQuestions' ?
            `/default/questionaries/${questionaryId}/questions/` :
            `/questionaries/${questionaryId}/questions/`;

        Toast.loading('Vragen opslaan');
        for(let index in questions){
            const question = questions[index];
            await httpPost(`${uriBase}${question.Id}`, keysToLower(question), jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        }
        Toast.hide();
        this.props.history.push(`/enquete`);
    };

    removeQuestion = async (index) => {
        const {questionsType} = this.props;
        const {questions, questionaryId} = this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;

        const uriBase = questionsType === 'defaultQuestions' ?
            `/default/questionaries/${questionaryId}/questions/` :
            `/questionaries/${questionaryId}/questions/`;

        Toast.loading('Vraag verwijderen');
        const question = questions[index];
        await httpDelete(`${uriBase}${question.Id}`, {}, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        questions.splice(index, 1);
        this.setState({
            questions
        });
        Toast.hide();
    };

    renderButtons = () => {
        const {questionsType} = this.props;
        const {questionaryId, clientnumber} = this.state;

        if (questionsType === 'defaultQuestions'){
            return(
                <div style={{textAlign: 'right'}}>
                    <Link to={`/enquete`} className='am-button am-button-inline am-button-small'>Annuleren</Link>
                    <Button inline size="small" type="primary"
                            onClick={this.saveQuestion}>Opslaan
                    </Button>
                </div>
            )
        } else {
            return(
                <div style={{textAlign: 'right'}}>
                    <Link to={`/enquete/${questionaryId}/contactpersonen/${clientnumber}`} className='am-button am-button-inline am-button-small'>Volgende</Link>
                </div>
            )
        }
    };

    handleClose = (e) => {
        console.log(e);
        this.setState({
            nameVisible: false
        });
    };

    changeName = () => {
        this.setState({
            nameVisible: true,
        });
    };

    saveName = async () => {
        const {changeName, clientnumber, questionaryId} = this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        await httpPost(`/${clientnumber ? '' : 'default/'}questionaries/${questionaryId}`,{
            name: changeName
        }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        this.setState({
            categoryName: changeName
        })
    };

    changeOthers = () => {
        const {questionaryId, questionary} = this.state;
        console.log('this.props.questionsType', this.props.questionsType);
        if (this.props.questionsType === 'defaultQuestions')
        {
            return (
                <div>
                    <h4>Welkomsttekst</h4>
                    <p dangerouslySetInnerHTML={{__html: questionary.Welcome}} />
                    <p><Link to={`/enquete/${questionaryId}/defaultwelkomsttekst`}
                             className="am-button am-button-inline am-button-small am-button-primary">Verander
                        welkomsttekst</Link></p>
                    <h4>Bedanktekst</h4>
                    <p dangerouslySetInnerHTML={{__html: questionary.Thanks}} />
                    <p><Link to={`/enquete/${questionaryId}/defaultbedankt`}
                             className="am-button am-button-inline am-button-small am-button-primary">Verander
                        bedanktekst</Link></p>
                </div>
            );

        } else {
            return (
                <div>
                    <h4>Welkomsttekst</h4>
                    <p dangerouslySetInnerHTML={{__html: questionary.Welcome}} />
                    <p><Link to={`/enquete/${questionaryId}/welkom`} className="am-button am-button-inline am-button-small am-button-primary">Verander welkomsttekst</Link></p>
                    <h4>Bedanktekst</h4>
                    <p dangerouslySetInnerHTML={{__html: questionary.Thanks}} />
                    <p><Link to={`/enquete/${questionaryId}/bedankt`} className="am-button am-button-inline am-button-small am-button-primary">Verander bedanktekst</Link></p>
                </div>
            );
        }
    };

    render() {
        const {categoryName, questions} = this.state;

        questions.sort((a, b) => a.Sequence - b.Sequence);

        const questionary = questions.map((question, index) => {
            return (
                <div key={index} className='textStyling'>
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        onClick={() => this.removeQuestion(index)}
                    />
                    <FontAwesomeIcon
                        icon={question.Type === 'text' ? faEdit : faStar}
                    />
                    <div className='questionNumber'>{index + 1}</div>
                    <input placeholder="De vraag" value={question.Text} onChange={
                        (event) => {
                            this.changeQuestion(index, event.target.value);
                        }
                    }/>
                </div>
            );
        });

        return (
            <div className="QuestionaryQuestions">
                <Card title={categoryName} headerButtons={[
                    {text: 'naam wijzigen', onPress: this.changeName}
                ]}>
                    <div>
                        {questionary}
                    </div>
                    <h4>Vraag toevoegen</h4>
                    <div>
                        <Button type='primary' size="small" inline className="am-button-250"
                                onClick={ () => {this.addQuestions('text')}}>
                            Invulvraag
                        </Button>
                        <Button type='primary' size="small" inline className="am-button-250"
                                onClick={ () => {this.addQuestions('rating')}}>
                            Sterren-vraag
                        </Button>
                    </div>

                    {this.changeOthers()}
                    {this.renderButtons()}
                </Card>

                <Modal
                    title="Naam wijzigen"
                    visible={this.state.nameVisible}
                    footer={[
                        { text: 'Annuleren', onPress: this.handleCancel  },
                        { text: 'Opslaan', onPress: async () => {
                            this.handleClose();
                            await this.saveName();
                        }}
                    ]}
                >
                    <h4>Naam van de enquête</h4>
                    <input onChange={(e) => {
                        this.setState({
                            changeName: e.target.value
                        });
                    }} value={this.state.changeName} name='name'/>
                </Modal>
            </div>
        );
    }
}

export default withApplicationState(withRouter(QuestionaryQuestions));
