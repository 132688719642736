import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionarycontactperson.scss";
import Card from "../../card/";
import PropTypes from "prop-types";
import applicationConfig from "../../../config/application";
import moment from "moment";
import {Button, InputItem, Modal, Toast} from "antd-mobile";
import {httpGet, httpPost} from "../../../lib/api";
import {withApplicationState} from "react-application-state";

class QuestionaryContactperson extends Component {
    static propTypes = {
        sendingQuestionary: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            clientnumber: props.match.params.clientnumber,
            questionaryId: props.match.params.id,
            modalVerzenden: false,
            modalToevoegen: false,
            clickedContact: [],
            Name: '',
            Role: '',
            Mail: '',
            Gsm: '',
            Telephone: '',
        }
    }

    componentWillMount = async () =>  {
        await this.refreshContacts();
    };

    refreshContacts = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const contacts = await httpGet(`/clients/${this.state.clientnumber}/contacts`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (contacts) {
            this.setState({
                contacts: contacts.response
            })
        }
    };

    onChangeName = (value) => {
        this.setState({
            Name: value
        });
    };

    onChangeRole = (value) => {
        this.setState({
            Role: value
        });
    };

    onChangeMail = (value) => {
        this.setState({
            Mail: value
        });
    };

    onChangeGsm = (value) => {
        this.setState({
            Gsm: value
        });
    };

    onChangeTelephone = (value) => {
        this.setState({
            Telephone: value
        });
    };

    showModal = (type) => {
        console.log(type);
        if (type === 'verzenden'){
            this.setState({
                modalVerzenden: true,
            })
        } else{
            this.setState({
                modalToevoegen: true
            })
        }
    };

    handleOk = async (type) => {
        console.log('type', type);
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        if(type === 'verzenden'){
            if(this.state.clickedContact.length > 0){
                console.log(this.state.clickedContact)
                const now = moment().format('YYYY-MM-DD');
                const text = applicationConfig.email.defaultText;
                const type = 'unsend';
                Toast.loading('Opslaan');
                //todo: clickedContact[0] is geen oplossing hier..
                //todo: moet a.d.h.v hele lijst
                const email = await httpPost(`/emails`, {
                    date: now,
                    text: text,
                    questionaryId: this.state.questionaryId,
                    contact: this.state.clickedContact[0].Id,
                    type: type,
                }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
                console.log('email', email)
                Toast.hide();
                this.props.history.push(`/email/${email.response.id}`);
            }
        } else {
            const {Name, Role, Mail, Gsm, Telephone, clientnumber} = this.state;
            Toast.loading('Opslaan');
            await httpPost(`/clients/${this.state.clientnumber}/contacts`, {
                name: Name,
                role: Role,
                mail: Mail,
                gsm: Gsm,
                telephone: Telephone,
                clientNumber: clientnumber
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

            await this.refreshContacts();
            Toast.hide();

            this.setState({
                modalToevoegen: false,
            });
        }
    };

    handleCancel = (type) => {
        if (type === 'verzenden'){
            this.setState({
                modalVerzenden: false
            });
        } else {
            this.setState({
                modalToevoegen: false
            })
        }

    };

    renderCardData = () => {
        const {questionaryId, clickedContact, contacts, modalToevoegen, Name, Role, Mail, Gsm, Telephone} = this.state;

        if(questionaryId === undefined){
            return (
                <div className='stylingCards'>
                    {
                        contacts.length > 0 ?
                            contacts.map((survey, index) =>
                                <div className='compositioning' key={index}>
                                    <Card key={index} title={survey.Name} className="contact">
                                        <p>
                                            <b>Rol:</b><br/>
                                            {survey.Role}<br/>
                                            <b>E-mail:</b><br/>
                                            {survey.Mail}<br/>
                                            <b>GSM:</b><br/>
                                            {survey.Gsm}<br/>
                                            <b>Tel:</b><br/>
                                            {survey.Telephone}<br/>
                                        </p>
                                    </Card>
                                </div>
                            ) : <p style={{padding: 0, margin: 0}}>Nog geen contactpersonen gevonden</p>

                    }
                </div>
            );
        } else {
            return (
                <div className='stylingCards'>
                    {
                        contacts.length > 0 ?
                            contacts.map((person, index) =>
                                <div
                                    className='compositioning'
                                    key={index}
                                    onClick={() => {
                                        if(clickedContact.filter(ccp => ccp.Name === person.Name).length){
                                            const index = clickedContact.findIndex(ccp => ccp.Name === person.Name);
                                            const newList = [...clickedContact];
                                            newList.splice(index, 1);
                                            this.setState({clickedContact: newList});
                                        }else{
                                            clickedContact.push(person);
                                            this.setState({clickedContact: clickedContact});
                                        }
                                    }}>
                                    <Card key={index}
                                          title={person.Name} className={`contact ${this.state.clickedContact.filter(ccp => ccp.Name === person.Name).length ? 'selected' : ''}`}>
                                        <p>
                                            <b>Rol:</b><br/>
                                            {person.Role}<br/>
                                            <b>E-mail:</b><br/>
                                            {person.Mail}<br/>
                                            <b>GSM:</b><br/>
                                            {person.Gsm}<br/>
                                            <b>Tel:</b><br/>
                                            {person.Telephone}<br/>
                                        </p>
                                    </Card>
                                </div>
                            ) : <p style={{padding: 0, margin: 0}}>Nog geen contactpersonen gevonden</p>

                    }
                </div>
            );
        }
    };


    renderButtons = () => {
        const {questionaryId, clickedContact} = this.state;

        if (questionaryId !== undefined){
            return (
                <div className='contactButton'>
                    <Button type="primary" onClick={ () => {this.showModal('verzenden')}}>
                        Verzenden
                    </Button>
                    <Modal
                        title="Verzenden naar:"
                        visible={this.state.modalVerzenden}
                        footer={[
                            { text: 'Annuleren', onPress: () => {this.handleCancel('verzenden')}},
                            { text: 'Ok', onPress: () => {this.handleOk('verzenden')}}
                        ]}
                        onClose={() => {this.handleCancel('verzenden')}}
                    >
                        <div>
                            {
                                clickedContact.length > 0 ?
                                    clickedContact.map((person, index) =>
                                        <div key={index}>
                                            <p>
                                                <b>Naam:</b><br/>
                                                {person.Name}<br/>
                                            </p>
                                        </div>
                                    ) : <p style={{padding: 0, margin: 0}}>Er zijn nog geen contactpersonen gekozen</p>

                            }
                        </div>
                    </Modal>
                </div>
            );
        }
    };

    render() {
        console.log('this.state.modalVerzenden', this.state.modalVerzenden);
        const {modalToevoegen, Name, Role, Mail, Gsm, Telephone} = this.state;
        return (
            <div className="QuestionaryContactperson">
                <Card title="Contactpersonen" className="table" bodyClass="table-card-body" headerClass="table-card-header" headerButtons={[
                    {onPress: () => this.showModal(''), text: 'Contactpersoon toevoegen'}
                ]}>
                    <Modal
                        title="Contactpersoon toevoegen"
                        visible={modalToevoegen}
                        footer={[
                            { text: 'Annuleren', onPress: () => {this.handleCancel('toevoegen')}},
                            { text: 'Ok', onPress: () => {this.handleOk('toevoegen')}}
                        ]}
                        onClose={() => {this.handleCancel('toevoegen')}}
                    >
                        <InputItem
                            value={Name}
                            placeholder="Naam"
                            onChange={this.onChangeName}
                        >Naam</InputItem>
                        <InputItem
                            value={Role}
                            placeholder="Rol"
                            onChange={this.onChangeRole}
                        >Rol</InputItem>
                        <InputItem
                            value={Mail}
                            placeholder="E-mail"
                            onChange={this.onChangeMail}
                        >E-mail</InputItem>
                        <InputItem
                            value={Telephone}
                            placeholder="Tel"
                            onChange={this.onChangeTelephone}
                        >Tel</InputItem>
                        <InputItem
                            value={Gsm}
                            placeholder="Gsm"
                            onChange={this.onChangeGsm}
                        >Gsm</InputItem>
                    </Modal>
                {this.renderCardData()}
                {this.renderButtons()}
                </Card>
            </div>
        );
    }
}

export default withApplicationState(withRouter(QuestionaryContactperson));
