import React, { Component, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import "../assets/clients.scss";
import {Button, InputItem, List, Modal, Picker, Toast} from "antd-mobile";
import Table from "rc-table";
import Card from "../../card";
import {withApplicationState} from "react-application-state";
import {httpGet, httpPost} from "../../../lib/api";
import applicationConfig from "../../../config/application";
import PropTypes from "prop-types";
import moment from "moment";

class Clients extends Component {
    static propTypes = {
        cardName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            visible: false,
            clientName: '',
            clientNumber: '',
            clientType: '',
            clientAddress: '',
            emails: '',
            emailsByDate: [],
            typeFilter: 'Alle Types',
            types: [
                {
                    Id: 1,
                    Name: 'Sportvereniging'
                }, {
                    Id: 2,
                    Name: 'Gemeente'
                }, {
                    Id: 3,
                    Name: 'Primair Onderwijs'
                }, {
                    Id: 4,
                    Name: 'Primair & Voortgezet Onderwijs'
                }, {
                    Id: 5,
                    Name: 'Voortgezet Onderwijs'
                }, {
                    Id: 7,
                    Name: 'Zorginstelling'
                }, {
                    Id: 8,
                    Name: 'Sportstichting'
                }, {
                    Id: 9,
                    Name: 'Sportbedrijf'
                }, {
                    Id: 10,
                    Name: 'Kinderopvang'
                }, {
                    Id: 11,
                    Name: 'Bibliotheek',
                }
            ],
            typeName: '',
        };
    };

    getCols = () => {
        return [{
            title: 'Naam',
            dataIndex: 'Name',
            key: 'Name',
            render: (text, record) => {
                return(
                    <Link to={`/contactpersonen/${record.ClientNumber}`} className='linkName'>{record.Name}</Link>
                )
            },
        }, {
            title: 'Klantnummer',
            dataIndex: 'ClientNumber',
            key: 'ClientNumber',
        }, {
            title: `Soort klant (${this.state.typeFilter})`,
            dataIndex: 'Type',
            key: 'Type',
            onHeaderCell: () => {return  {onClick: () => this.cycleTypes()}}
        }
            , {
            title: 'Adres',
            dataIndex: 'Address',
            key: 'Address',
        }, {
                title: 'Enquete status',
                key: 'status',
                className: 'button-col',
                render: (text, record) => {
                    const buttons = [];
                    if (record.questionaries) {
                        record.questionaries.forEach(questionary => {
                            if (questionary.Status === 'aangemaakt') {
                                buttons.push(
                                    <Link
                                        to={`/enquete/${questionary.QuestionaryId}/vragen/${questionary.ClientNumber}`}
                                        className='am-button am-button-primary am-button-small am-button-inline'>Bewerken</Link>
                                );
                            } else if (questionary.Status === 'verzonden') {
                                buttons.push(
                                    <Fragment>
                                        <p>
                                            <Link
                                                to={`/enquete/${questionary.QuestionaryId}/vragen/${questionary.ClientNumber}`}
                                                className='am-button am-button-primary am-button-small am-button-inline'>Verzonden
                                                op ({moment(questionary.SendDate).format('DD/MM/YYYY')})</Link>
                                        </p>
                                    </Fragment>
                                );
                            } else if (questionary.Status === 'ingevuld') {
                                buttons.push(
                                    <Link to={`/uitslagen/${record.ClientNumber}/${questionary.QuestionaryId}`}
                                          className='am-button am-button-primary am-button-small am-button-inline'>Uitslag</Link>
                                );
                            }
                        });
                    }

                    return <Fragment>
                        {buttons.map((row, index) =>
                            <div key={index}>{row}</div>
                        )}
                    </Fragment>;
                }
        }, {
            title: 'Nieuwe enquête',
            key: 'new',
            className: 'button-col',
            render: (text, record) => {
                return <Link to={`/enquete/${record.ClientNumber}`} className='am-button am-button-primary am-button-small am-button-inline'>Aanmaken</Link>;
            }
        }]
    };

    cycleTypes = () => {
        const {typeFilter} = this.state;
        let newFilter = typeFilter;
        switch(typeFilter){
            case 'Primair Onderwijs':
                newFilter = 'Voortgezet Onderwijs';
                break;
            case 'Voortgezet Onderwijs':
                newFilter = 'Sportvereniging';
                break;
            case 'Sportvereniging':
                newFilter = 'Zorginstelling';
                break;
            case 'Zorginstelling':
                newFilter = 'Gemeente';
                break;
            case 'Gemeente':
                newFilter = 'Primair & Voortgezet Onderwijs';
                break;
            case 'Primair & Voortgezet Onderwijs':
                newFilter = 'Sportstichting';
                break;
            case 'Sportstichting':
                newFilter = 'Sportbedrijf';
                break;
            case 'Sportbedrijf':
                newFilter = 'Kinderopvang';
                break;
            case 'Kinderopvang':
                newFilter = 'Bibliotheek';
                break;
            case 'Bibliotheek':
                newFilter = 'Alle Types';
                break;
            default:
                newFilter = 'Primair Onderwijs';
                break;
        }
        console.log(typeFilter, newFilter);
        this.setState({typeFilter: newFilter});
    };

    componentWillMount = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {emailsByDate} = this.state;
        const today = moment();

        const result = await httpGet(`/emails`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (result) {
            this.setState({
                emails: result.response,
            });
        }

        // for (let i = 0; i < this.state.emails.length; i++) {
        //     const email = this.state.emails[i];
        //     const difference = moment(email.Date).diff(today, 'days');
        //     if (difference <= -14) {
        //         emailsByDate.push(email);
        //     }
        // }

        await this.refreshClients();
    };

    refreshClients = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {cardName} = this.props;
        const {emailsByDate} = this.state;
        const today = moment();

        const clients = await httpGet(`/clients`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (clients) {
            this.setState({
                clients: clients.response
            })
        }

        if (cardName === 'Dashboard'){
            for (let i = 0; i < this.state.clients.length; i++) {
                console.log('clients', this.state.clients);
                const email = this.state.clients[i].questionaries;
                console.log('email', email);
                const difference = email ? moment(email.SendDate).diff(today, 'days') : 1;
                console.log('difference', difference);
                if (difference <= -14) {
                    emailsByDate.push(email);
                }

                console.log('emailsByDate', emailsByDate);
            }
        } else {
            return '';
        }

    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = async () => {
        Toast.loading('Opslaan');
        const {clientName, clientNumber, typeName, clientAddress} = this.state;

        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;

        await httpPost(`/clients`, {
            name: clientName,
            clientNumber: clientNumber,
            type: typeName,
            address: clientAddress,
        }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

        this.setState({
            visible: false,
        });
        await this.refreshClients();
        Toast.hide();
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleChange = (value) => {
        this.setState({
            typeName: value
        });
    };

    onChangeName = (value) => {
        this.setState({
            clientName: value
        });
    };

    onChangeNumber = (value) => {
        this.setState({
            clientNumber: value
        });
    };

    onChangeAddress = (value) => {
        this.setState({
            clientAddress: value
        });
    };

    render() {
        const {clientName, clientNumber, clientAddress, types, typeName, clients, emails, emailsByDate, typeFilter} = this.state;
        const {cardName} = this.props;
        const columns = this.getCols();
        let headerButton = [];

        if (cardName === 'Dashboard'){
            headerButton = [];
        } else {
            headerButton = [
                {onPress: this.showModal, text: 'Klant toevoegen'}
            ]
        }
        const data = clients.filter(client => client.Type === typeFilter || typeFilter === 'Alle Types');
        data.sort((a, b) => {
            const nameA = a.Name.toLowerCase();
            const nameB = b.Name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        })

        return (
            <div className={this.props.cardName}>
                <Card title={this.props.cardName}
                      headerButtons={headerButton}>
                    <Modal
                        title="Klant toevoegen"
                        visible={this.state.visible}
                        footer={[
                            {text: 'Annuleren', onPress: () => this.handleCancel()},
                            {text: 'Ok', onPress: () => this.handleOk()}
                        ]}
                        onClose={() => {
                            this.handleCancel()
                        }}
                    >
                        <List>
                            <InputItem
                                value={clientName}
                                placeholder="Klantnaam"
                                onChange={this.onChangeName}
                            >Klant naam</InputItem>
                            <InputItem
                                value={clientNumber}
                                placeholder="Klantnummer"
                                onChange={this.onChangeNumber}
                            >Klant nummer</InputItem>
                            {
                                types.length > 0 ?
                                    <Picker data={
                                        types.map((survey, index) => {
                                            return {
                                                label: survey.Name,
                                                value: survey.Name
                                            }
                                        })
                                    } cols={1} onChange={this.handleChange} value={typeName} okText="Ok"
                                            dismissText="Annuleer" extra="Kies">
                                        <List.Item arrow="horizontal">Organisatie type</List.Item>
                                    </Picker> :
                                    <p style={{padding: 0, margin: 0}}>Geen type gevonden</p>
                            }
                            <InputItem
                                value={clientAddress}
                                placeholder="Adres"
                                onChange={this.onChangeAddress}
                            >Adres</InputItem>
                        </List>
                    </Modal>
                    {
                        columns.length > 0 ?
                            <Table columns={columns} data={data} rowKey="ClientNumber" pagination={false}
                                   style={{marginBottom: '30px'}}/>
                            : <p>Nog geen klanten gevonden</p>
                    }
                </Card>
            </div>
        )
    }
}

export default withApplicationState(withRouter(Clients));
