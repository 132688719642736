import React, {Component, Fragment} from 'react';
import {withRouter, Link} from "react-router-dom";
import "../assets/questionaries.scss";
import {Button, Modal, Picker, List, Toast} from 'antd-mobile';
import applicationConfig from "../../../config/application";
import Card from "../../card";
import {httpGet, httpPost, httpDelete} from "../../../lib/api";
import {withApplicationState} from "react-application-state";

class Questionaries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: {},
            delete: false,
            add: false,
            push: false,
            newCategoryName: '',
            defaultCategories: {},
            clientnumber: props.match.params.clientnumber,

            name: '',
            welcome: `<p>Hallo!</p>
<p>Bewustwording vinden we belangrijk en is onderdeel van onze verduurzamingsaanpak.</p> 
<p>We installeren het door ons ontwikkelde Energiescherm, organiseren ludieke en educatieve bewustwordingsactiviteiten.</p> 
<p>We zijn benieuwd naar jouw mening en willen er graag van leren. <br/>
Zou je circa 3 minuten de tijd willen nemen om deze enquete in te vullen? <br/>
Je helpt ons er enorm mee! </p>

<p>Met opgewekte groet,<br/> 
Elke Stienissen <br/>
Team bewustwording</p>`,
            thanks: '<p>Dit was het al! Heel erg bedankt voor je tijd.</p>',
            defaultCategoryId: '',
            project: '',
            deleteCategoryId: 0
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.clientnumber !== this.state.clientAddress)
            this.setState({clientnumber: nextProps.match.params.clientnumber});
    }

    componentWillMount = async () => {
        await this.refreshQuestionaries();
        await this.refreshCategories();
    };

    refreshQuestionaries = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const categories = await httpGet(`/default/questionaries`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (categories) {
            this.setState({
                categories: categories.response
            })
        }
    };

    refreshCategories = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const categories = await httpGet(`/categories`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (categories) {
            this.setState({
                defaultCategories: categories.response,
                defaultCategoryId: categories.response[0].Id
            })
        }
    }

    showModal = (modalData) => {
        if (modalData === 'addCategory') {
            this.setState({
                add: true,
            })
        } else if (modalData === 'deleteCategory') {
            this.setState({
                delete: true,
            })
        } else {
            this.setState({
                push: true,
                name: modalData
            })
        }
    };

    handleOk = async (modalData) => {
        Toast.loading('Opslaan');
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        if (modalData === 'addCategory') {
            const {newCategoryName, welcome, thanks, defaultCategoryId} = this.state;

            await httpPost(`/default/questionaries`, {
                name: newCategoryName,
                welcome: welcome,
                thanks: thanks,
                categoryId: defaultCategoryId
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            await this.refreshQuestionaries();

            this.setState({
                add: false,
            });
            Toast.hide();
        } else if (modalData === 'deleteCategory') {
            if(this.state.deleteCategoryId){
                const {deleteCategoryId} = this.state;

                const response = await httpDelete(`/default/questionaries/${deleteCategoryId}`, jwtToken,
                    () => {});

                Toast.hide();
                if(response && response.status === 'success')
                    await this.refreshQuestionaries();
                else
                    Toast.fail('Kan enquête categorie niet verwijderen omdat deze in gebruik is',5)

                this.setState({
                    delete: false,
                    deleteCategoryId: 0
                });
            }else{
                this.setState({
                    delete: false,
                });
            }
        } else {
            this.setState({
                push: false,
            })
        }
    };

    handleCancel = (modalData) => {
        if (modalData === 'addCategory') {
            this.setState({
                add: false,
            })
        } else if (modalData === 'deleteCategory') {
            this.setState({
                delete: false,
                deleteCategoryId: 0
            })
        } else {
            this.setState({
                push: false,
            })
        }

    };

    deleteCategorie = (id) => {
        this.setState({
            deleteCategoryId: id
        });
    };

    handleChange = (value) => {
        this.setState({
            defaultCategoryId: value
        });
    };

    modals = (modalData) => {
        if (modalData === 'addCategory') {
            const {newCategoryName, defaultCategories, defaultCategoryId} = this.state;
            return (
                <Modal
                    title="Standaard-enquête toevoegen"
                    visible={this.state.add}
                    footer={[
                        { text: 'Annuleren', onPress: () => this.handleCancel('addCategory')  },
                        { text: 'Ok', onPress: () => this.handleOk('addCategory') }
                    ]}
                    onClose={() => {
                        this.handleCancel('addCategory')
                    }}
                >
                    <input onChange={this.onChange} value={newCategoryName} name='newCategoryName'/>
                    {
                        defaultCategories.length > 0 ?
                            <Picker data={
                                defaultCategories.map((survey, index) => {
                                    return {
                                        label: survey.Name,
                                        value: survey.Id
                                    }
                                })
                            } cols={1} value={defaultCategoryId} onChange={this.handleChange} okText="Ok" dismissText="Annuleer" extra="Kies">
                                <List.Item arrow="horizontal">Kies een categorie</List.Item>
                            </Picker> :
                            <p style={{padding: 0, margin: 0}}>Nog geen categorie gevonden</p>
                    }
                </Modal>
            );
        } else if (modalData === 'deleteCategory') {
            return (
                <Modal
                    title="Standaard-enquête verwijderen"
                    visible={this.state.delete}
                    footer={[
                        { text: 'Annuleren', onPress: () => this.handleCancel('deleteCategory')  },
                        { text: 'Ok', onPress: () => this.handleOk('deleteCategory') }
                    ]}
                    onClose={() => {
                        this.handleCancel('deleteCategory')
                    }}
                >
                    {this.renderCategories('modal')}
                </Modal>
            );
        }
    };

    pushQuestionary = async (surveyId) => {
        const {clientnumber} = this.state;

        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const response = await httpPost(`/questionaries`, {
            defaultQuestionaryId: surveyId,
                clientNumber: clientnumber
        }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

        this.props.history.push(`/enquete/${response.response.id}/vragen/${clientnumber}`);
    };

    renderCategories = (type) => {
        const {clientnumber, deleteCategoryId} = this.state;
        if (type === 'modal') {
            return (
                <Fragment>
                    <h4>Kies een standaard enquête</h4>
                    <div className='categories'>
                        {
                            this.state.categories.length > 0 ?
                                this.state.categories.map((survey, index) =>
                                    <div key={index}>
                                        <Button onClick={() => this.deleteCategorie(survey.Id)} type={deleteCategoryId === survey.Id ? 'primary' : 'default'} inline className="am-button-250">{survey.Name}</Button>
                                    </div>
                                ) : <p style={{padding: 0, margin: 0}}>Nog geen enquete gevonden</p>
                        }
                    </div>
                </Fragment>
            )
        } else {
            if (clientnumber === undefined) {
                return (
                    <Fragment>
                        <h4>Kies een enquête</h4>
                        <div className='categories'>
                            {
                                this.state.categories.length > 0 ?
                                    this.state.categories.map((survey, index) =>
                                        <div key={index}>
                                            <Link to={`/enquete/${survey.Id}/defaultvragen`} className='am-button am-button-primary' name={survey.Name}>{survey.Name}</Link>
                                        </div>
                                    ) : <p style={{padding: 0, margin: 0}}>Nog geen enquete gevonden</p>
                            }
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <h4>Kies een standaard enquête</h4>
                        <div className='categories'>
                            {
                                this.state.categories.length > 0 ?
                                    this.state.categories.map((survey, index) =>
                                        <div key={index}>
                                            <Button onClick={() => {this.pushQuestionary(survey.Id)}} type="primary">{survey.Name}</Button>
                                        </div>
                                    ) : <p style={{padding: 0, margin: 0}}>Nog geen enquete gevonden</p>
                            }
                            {this.modals('pushCategory')}
                        </div>
                    </Fragment>
                )
            }
        }

    };

    onChange = (e) => {
        this.setState({
            newCategoryName: e.target.value
        });
    };

    renderButtons = () => {
        const {clientnumber} = this.state;

        if (clientnumber === undefined) {
            return (
                <Fragment>
                    <h4>Beheer standaard-enquêtes</h4>
                    <div className='buttons'>
                        <Button type='primary' size="small" onClick={() => {
                            this.showModal('addCategory')
                        }}>Standaard-enquête toevoegen</Button>
                        <Button type='primary' size="small" onClick={() => {
                            this.showModal('deleteCategory')
                        }}>Standaard-enquête verwijderen</Button>
                        {this.modals('deleteCategory')}
                        {this.modals('addCategory')}
                    </div>
                </Fragment>
            )
        } else {
            return ''
        }
    };

    render() {
        const {clientnumber} = this.state;
        return (
            <div className="Questionaries">
                <Card title={clientnumber ? "Enquête aanmaken" : "Standaard enquêtes bewerken"}>
                    {this.renderCategories('list')}
                    {this.renderButtons()}
                </Card>
            </div>
        )
    }
}

export default withApplicationState(withRouter(Questionaries));
