import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionaryresult.scss";
import Card from "../../card";
import {httpGet} from "../../../lib/api";
import applicationConfig from "../../../config/application";
import {withApplicationState} from "react-application-state";
import {Toast} from "antd-mobile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faStar} from "@fortawesome/free-regular-svg-icons";
import {faUserFriends, faStar as solidStar} from "@fortawesome/free-solid-svg-icons";

class QuestionaryResult extends Component {
    constructor (props) {
        super(props);

        this.state = {
            clientnumber: props.match.params.clientnumber,
            questionaryId: props.match.params.questionaryId,
            loading: false,
            questionary: {},
            questions: [],
            answers: [],
            contacts: [],
            questionActive: 0
        }
    }

    componentWillMount() {
        this.loadResults();
    }

    loadResults = async () => {
        Toast.loading('Resultaten ophalen');
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {questionaryId} = this.state;
        const questionary = await httpGet(`/questionaries/${questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        const questions = await httpGet(`/questionaries/${questionaryId}/questions`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        const answers = await httpGet(`/questionaries/${questionaryId}/answers`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        const contacts = await httpGet(`/contactQuestionaryCombinations/${questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));


        this.setState({
            questionary: questionary.response,
            questions: questions.response,
            answers: answers.response,
            contacts: contacts.response,
            loading: false,
        })
        Toast.hide();
    };

    render() {
        const {loading, questionary, questions} = this.state;
        console.log('answer', this.state.answers);
        console.log('questions', questions);
        return (
            <div className="QuestionaryResult">
                {
                    !loading ?
                        <Card title={`Uitslagen ${questionary.Name} ${questionary.CategoryName ? `(${questionary.CategoryName})`: ''}`} className="table" bodyClass="table-card-body" headerClass="table-card-header">
                            <ListQuestions
                                dataSource={questions}
                                onClick={(id) => {
                                    this.setState({questionActive: this.state.questionActive === id ? 0 : id})
                                }}
                                {...this.state}
                            />
                        </Card> : ''
                }
            </div>
        )
    }
}

const ListQuestions = (props) => {
    props.questions.sort((a, b) => a.Sequence - b.Sequence);
    return <div>
        {props.questions.map(question => <QuestionRow key={question.Sequence} question={question} {...props} />)}
    </div>
};

const QuestionRow = (props) => {
    console.log('props.question.type', props.question.Type);
    const renderStars = (value) => {
        console.log('value',value);

        const renderStar = (position, active) => {
            return <div className="rate-star" key={position}>
                <FontAwesomeIcon
                    icon={active ? solidStar : faStar}
                />
            </div>
        };

        let count = 0;
        let stars = [];
        while(count < value) {
            stars.push(renderStar(count + 1, true));
            count++;
        }
        while(count < 5) {
            stars.push(renderStar(count + 1, false));
            count++;
        }
        return stars;
    };

    const renderText = (value) => {
        return value;
    };

    return (
        <div onClick={() => props.onClick(props.question.Id)} className="question-row">
            <div className="header">
                <div className="name">
                    {props.question.Text}
                </div>
                <div className="type">
                    <FontAwesomeIcon
                        icon={props.question.Type === 'text' ? faEdit : faStar}
                    />
                </div>
                <div className="answers">
                    <FontAwesomeIcon
                        icon={faUserFriends}
                    />&nbsp;{props.answers.filter(a => a.QuestionId === props.question.Id).length}
                </div>
            </div>
            {
                true || props.questionActive === props.question.Id ?
                    <div className="body">
                        {
                            props.contacts.map(contact => {
                                const answer = props.answers.filter(a => a.Id === contact.Id && a.QuestionId === props.question.Id)[0];
                                return (
                                    <div className="answer-row">
                                        <div className="name">
                                            {contact.Name}
                                        </div>
                                        {answer ? (
                                            props.question.Type === 'text' ?
                                                <div className="answer">{renderText(answer.Value)}</div> :
                                                <div className="rating">{renderStars(answer.Value)}</div>
                                        ):
                                            <div className="answer">Nog geen antwoord</div>}
                                    </div>
                                )
                            })
                        }
                    </div> : ''
            }
        </div>
    );
}



export default withApplicationState(withRouter(QuestionaryResult));
