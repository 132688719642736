import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import "../assets/card.scss";
import {Button} from "antd-mobile";

class Card extends Component {
    static propTypes = {
        title: PropTypes.string,
        headerClass: PropTypes.string,
        bodyClass: PropTypes.string,
        className: PropTypes.string,
        headerButtons: PropTypes.arrayOf(
            PropTypes.shape({
                onPress: PropTypes.func,
                text: PropTypes.string
            })
        )
    };

    static defaultProps = {
        title: '',
        headerClass: '',
        bodyClass: '',
        className: '',
        headerButtons: []
    };

    render() {
        const {headerClass, bodyClass, className, title, headerButtons, children} = this.props;
        return (
            <div className={`Card ${className}`}>
                <div className={`card-header ${headerClass}`}>
                    <h2>{title}</h2>
                    {
                        headerButtons.length ?
                            <div className="card-header-button-container">
                                {
                                    headerButtons.map((button, index) => <Button key={index} inline size="small" onClick={button.onPress}>{button.text}</Button>)
                                }
                            </div>
                            : ''
                    }
                </div>
                <div className={`card-body ${bodyClass}`}>
                    {children}
                </div>
            </div>
        )
    }
}

export default withRouter(Card);
