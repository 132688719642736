import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionarydashboard.scss";
import {httpGet} from "../../../lib/api";
import applicationConfig from "../../../config/application";
import {withApplicationState} from "react-application-state";
import moment from "moment";
import Clients from "../../clients";
import Card from '../../card';

class QuestionaryDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: '',
            dboContactQuestionaryCombinationId: '',
            dboQuestionaryId: '',
            dboClientNumber: '',
            emailsByDate: [],
        }
    }

    componentWillMount = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const emailsByDate = [];
        const today = moment();
        const newState = {};


        const result = await httpGet(`/emails`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if (result) {
            newState.email = result.response;
        }

        for (let i = 0; i < this.state.emails.length; i++) {
            const email = this.state.emails[i];
            const difference = moment(email.Date).diff(today, 'days');
            if (difference <= -14) {
                emailsByDate.push(email);
            }
        }

        if(emailsByDate.length > 0){
            newState.emailsByDate = emailsByDate;
            for(let i = 0; i < emailsByDate.length; i++) {
                const contact = await httpGet(`/contactQuestionaryCombinations/${emailsByDate[i].ContactQuestionaryCombinationId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
                if (contact) {
                    newState.dboContactQuestionaryCombinationId = contact.response;
                }
                console.log('emailsByDate', emailsByDate);
            }
        }

        this.setState(newState);
    };

    render() {
        return (
            <div className="QuestionaryDashboard">
                <Clients cardName={'Dashboard'}/>
            </div>
        );
    }
}

export default withApplicationState(withRouter(QuestionaryDashboard));
