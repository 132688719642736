import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionarythanks.scss";
import applicationConfig from "../../../config/application";
import PropTypes from "prop-types";
import {httpGet, httpPost} from "../../../lib/api";
import {withApplicationState} from "react-application-state";
import {Button, TextareaItem, Toast} from "antd-mobile";
import Card from "../../card";
import ContentEditor from "../../contenteditor";

class QuestionaryThanks extends Component {
    static propTypes = {
        name: PropTypes.string,
        thanksType: PropTypes.string,
    };

    constructor(props){
        super(props);

        this.state = {
            thanks: '',
            questionaryId: props.match.params.id,
        }
    }

    componentWillMount = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {thanksType} = this.props;

        if (thanksType === 'defaultThanks'){
            const result = await httpGet(`/default/questionaries/${this.state.questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if(result) {
                this.setState({
                    name: result.response.Name,
                    thanks: result.response.Thanks
                })
            }
        } else {
            const result = await httpGet(`/questionaries/${this.state.questionaryId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            if(result) {
                this.setState({
                    name: result.response.Name,
                    thanks: result.response.Thanks
                })
            }
        }

    };

    onChange = (value) => {
        this.setState({
            thanks: value,
        })
    };

    save = async () => {
        Toast.loading('Opslaan');
        const {thanks, questionaryId} =  this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {thanksType} = this.props;

        if (thanksType === 'defaultThanks'){
            await httpPost(`/default/questionaries/${questionaryId}`, {
                id: questionaryId,
                thanks: thanks,
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        } else {
            await httpPost(`/questionaries/${questionaryId}`, {
                id: questionaryId,
                thanks: thanks,
            }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        }

        Toast.hide();
        this.props.history.goBack();
    };

    render() {

        const {thanks} = this.state;
        return (
            <div className="QuestionaryWelcome">
                <Card title="Bedanktekst aanpassen" className="table">
                    {thanks ? <ContentEditor initialContent={thanks} onChange={this.onChange} /> : ''}
                    <div>
                        <Button inline onClick={() => this.props.history.goBack()}>Annuleren</Button>
                        <Button inline type="primary" onClick={this.save}>Opslaan</Button>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withApplicationState(withRouter(QuestionaryThanks));
