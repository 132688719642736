import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/questionaryemail.scss";
import Card from "../../card";
import {Button, TextareaItem, Toast} from "antd-mobile";
import {httpGet, httpPost} from "../../../lib/api";
import applicationConfig from "../../../config/application";
import {withApplicationState} from "react-application-state";
import ContentEditor from '../../contenteditor';
import moment from "moment";

class QuestionaryEmail extends Component {

    constructor(props){
        super(props);

        this.state = {
            emailId: props.match.params.id,
            email: '',
            contactQuestionaryCombinationId: 0,
            type: ''
        }
    }

    componentWillMount = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const result = await httpGet(`/emails/${this.state.emailId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(result) {
            this.setState({
                email: result.response.Text,
                contactQuestionaryCombinationId: result.response.ContactQuestionaryCombinationId,
                type: result.response.Type
            })
        }
    };

    onChange = (value) => {
        this.setState({
            email: value,
        })
    };

    save = async () => {
        Toast.loading('Opslaan');
        const {email, emailId, contactQuestionaryCombinationId, type} =  this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const date = moment().format("YYYY-MM-DD");
        console.log('date', date);
        await httpPost(`/emails/${emailId}`, {
            text: email,
            contactQuestionaryCombinationId: contactQuestionaryCombinationId,
            type: type,
            date: date,
        }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        Toast.hide();
        Toast.success('Doorverwijzen voor het verzenden van email');
        setTimeout(() => {
            window.location.href = `${applicationConfig.api.uri}/emails/${this.state.emailId}/send?returnUri=${window.location.origin}/klanten`;
        },1000);
    };
    render() {
        const {email} = this.state;
        console.log('email', email)
        return (
            <div className="QuestionaryEmail">
                <Card title="Begeleidende email bewerken" className="table">
                    {email ? <ContentEditor initialContent={email} onChange={this.onChange} /> : ''}
                    <div>
                        <Button inline type="primary" onClick={this.save}>Verzenden</Button>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withApplicationState(withRouter(QuestionaryEmail));
