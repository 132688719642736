export default {
    JWT: {
        allowedAudience: 'so-kto',
        allowedIssuer: 'so-kto',
        serverUri: 'https://kto-api.slimopgewekt.app/auth',
        tokenExpiredMessage: 'Je sessie is verlopen! Helaas betekend dat dat je opnieuw moet inloggen.'
    },
    theme: {
        colors: {
            primary: '#ffeb70',
            secondary: '#7bebcf',
            tertiary: '#252166'
        }
    },
    api: {
        uri: 'https://kto-api.slimopgewekt.app/kto'
        // uri: 'http://localhost:5005/kto'
    },
    email: {
        defaultText: `<p>Beste #NAAM#,</p>
<p>Hierbij ontvang je de vragenlijst over onze activiteiten.<br/>Graag zien we jouw beoordeling tegemoet.</p>
<p><a href="#ADRES-CODE#">Klik hier</a> om de vragenlijst in te vullen.<br/>Ook kan je de code handmatig invullen op <a href="#ADRES#">#ADRES#</a><br/>Hiervoor gebruik je de code: #CODE#</p>
<p>Met vriendelijke groet,<br/>Christine Voet</p>`
    }
}
